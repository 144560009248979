import React from 'react'
import Select from 'components/Forms/BambusSelect'
import countries from 'utils/countries'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import {
  Box,
  Flex,
  Text,
  Heading,
  Button as ButtonBase,
  Link,
  Image,
  Card,
} from 'rebass'

const options = countries.map((c) => ({
  icon: c.code.toLowerCase(),
  value: c.prefix,
  label: c.name,
  left: c.prefix,
}))

const formatOptionLabel = ({ label, value, icon }) => (
  <Box
    sx={{
      display: 'flex',
    }}
  >
    <Box
      sx={{
        flex: 1,
      }}
    >
      <Image
        src={findFlagUrlByIso2Code(icon)}
        alt={icon}
        sx={{
          display: 'inline-block',
          verticalAlign: 'middle',
          width: '22px',
          borderRadius: '1px',
          marginRight: '10px',
          marginTop: '-4px',
        }}
      />
      <span className="react-select-option--hide-in-single">{label}</span>
      <span className="react-select-option--hide-in-menu">{value}</span>
    </Box>

    <Text
      sx={{
        marginLeft: '0px',
        color: 'rgba(1, 42, 89, 0.5)',
      }}
    >
      <span className="react-select-option--hide-in-single">{value}</span>
    </Text>
  </Box>
)

const CountryCodeSelect = React.forwardRef(
  ({ value, onChange, ...props }, ref) => (
    <Select
      defaultValue={options[0]}
      value={options.find((option) => option.value === value)}
      options={options}
      formatOptionLabel={formatOptionLabel}
      onChange={(option) => onChange(option.value)}
      inputRef={ref}
      isPrefix
      {...props}
    />
  )
)

export default CountryCodeSelect
